<template>
  <div class="csn-game">
    <GameLists
      v-if="slug"
      :getList="getGameList"
      :itemsPerScreen="itemsPerScreen"
      :hasVericalUpperRow="true"
      :slug="slug"
    />
  </div>
</template>
<script>
import { cardsPerScreenCalculationMixin } from '@/mixins'
import { GameApi } from '@/api'

export default {
  name: 'GameCategory',
  mixins: [cardsPerScreenCalculationMixin],
  components: {
    GameLists: () => import('@/components/GameLists'),
  },
  data() {
    return { slug: null }
  },
  methods: {
    getGameList({ page, limit }) {
      return GameApi.getGameCategoryItemList({ param: this.slug, page, limit })
    },
  },
  watch: {
    $route: {
      handler({ params }) {
        this.slug = params.slug
      },
      immediate: true,
    },
  },
}
</script>
